import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectModal } from 'src/components/modal';
import { QuestaoQuestionarioResponse } from 'src/dtos/questaoQuestionarioResponse';
import { QuestionarioInfo } from 'src/dtos/questionariosInfoResponse';
import { useStorage } from 'src/global/hooks/useStorage';
import { useProvider } from 'src/provider/provider';
import { CardQuestion } from '../../components/cardQuestion';
import { StepForm } from '../../components/stepForm';
import { useToast } from '../../global/toast/useToast';
import { useHealthQuiz } from './useHealthQuiz';

export function RegistrarBeneficiary() {
  const { control, register, handleSubmit, setValue, reset } = useForm();
  const navigate = useNavigate();
  const { fields } = useFieldArray({
    control,
    name: 'respostas',
  });
  const [questions, setQuestions] = useState<QuestaoQuestionarioResponse[]>([]);
  let [searchParams] = useSearchParams();
  const newRender = searchParams.get('renderer');
  const [dataForm, setDataForm] = useState<any>([]);
  const [dataFormUsers, setDataUsers] = useState<any>([]);
  const [accessPage, setAccessPage] = useState(0);
  const [userSelect, setUserSelect] = useState<any>();
  const [indexSelect, setIndexSelect] = useState<number>();

  const { toastErro } = useToast();
  const {
    declaracaoId,
    ipPublic,
    questionariosInfo,
    controlModal,
    setModalControl,
  } = useProvider();
  const { selectStorage, removeStorage } = useStorage();
  const { getQuestions, setQuestionsAll } = useHealthQuiz();
  const validateIndex = (val: number) => {
    return val !== null && val !== undefined;
  };

  useEffect(() => {
    selectUserForm();
    if (validateIndex(indexSelect!)) {
      console.log(indexSelect);
      const fetchQuizQuestions = (index: number) => {
        console.log('fun: ', indexSelect);
        getQuestions(declaracaoId, index).then((res) => setQuestions(res));
      };
      if (String(indexSelect).length >= 1 || indexSelect === 0) {
        fetchQuizQuestions(indexSelect!);
      }
    }
  }, [indexSelect, newRender]);

  const validateInput = (val: any, index: number) => {
    if (val?.respostas[index]?.resposta != 'N') {
      if (
        val?.respostas[index]?.observacao &&
        val?.respostas[index]?.resposta
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const selectUserForm = async () => {
    const indexUser = await selectStorage('indexUser');
    console.log('INICIOU ASSIM: ', indexUser);
    setIndexSelect(Number(indexUser));
    return indexUser;
  };

  const selectUsers = useCallback(async () => {
    if (questionariosInfo) {
      setDataUsers(questionariosInfo);
    }
  }, [selectStorage, questionariosInfo]);

  const titleUser = async () => {
    const questionarioInfo = selectStorage('questionariosInfo');
    if (questionarioInfo) {
      const questionarioInfoParsed = JSON.parse(
        questionarioInfo
      ) as QuestionarioInfo[];
      const questionarioTitular = questionarioInfoParsed[indexSelect!];
      setUserSelect(questionarioTitular);
    }
  };

  useEffect(() => {
    if (accessPage != 0) {
      validateFields(dataForm?.respostas ? dataForm?.respostas : []);
    }
    selectUserForm();
    if (validateIndex(indexSelect!)) {
      selectUsers();
      titleUser();
    }
    setAccessPage(1);
  }, [dataForm, selectUsers, newRender, indexSelect]);

  const validateChangeInput = (arr: any) => {
    const filterData = arr?.filter((val: any) => val?.resposta === 'S');
    const filter = filterData.filter(
      (val: any) => val?.observacao === undefined
    );

    return filter?.length;
  };

  const validateFields = (arr: any) => {
    const result = arr?.map((val: any) => {
      return val?.resposta === undefined || val?.resposta === '';
    });

    const hasFalse = result?.some((value: any) => value === true);

    if (String(indexSelect) === '') {
      toastErro('Selecione um usuário para realizar o questionario');
      return;
    }

    if (hasFalse || validateChangeInput(arr) >= 1) {
      toastErro(
        'Responda todas as perguntas primeiro para seguir para próxima etapa. Verifique todas questões em vermelho.'
      );
      return;
    } else {
      const ip = ipPublic;
      const questionarioInfo = selectStorage('questionariosInfo');
      console.log('dataForm?.respostas:::', dataForm?.respostas);
      if (questionarioInfo && !hasFalse) {
        const questionarioInfoParsed = JSON.parse(
          questionarioInfo
        ) as QuestionarioInfo[];
        const questionarioTitular = questionarioInfoParsed[indexSelect!]; // - 1 - 2 - 3 saber qual dependente
        setUserSelect(questionarioTitular);
        setQuestionsAll(
          questionarioTitular?.questionarioId?.toString(),
          dataForm?.respostas,
          declaracaoId,
          ip
        )
          .then((res) => {
            reset();
            setDataForm([]);
            if (indexSelect === 0) {
              navigate(`/PhotoCapture?id=${indexSelect}`);
            } else if (indexSelect! >= 1) {
              console.log('tah aqui');
              setModalControl(true);
              reset();
              setDataForm([]);
            }
          })
          .catch((err) => {
            console.error('deu erro: ', err);
          });
      } else {
        toastErro('Problema ao pegar questionario Id');
      }
    }
  };

  return (
    <div className="w-screen h-auto pt-10 pb-16">
      <StepForm itemSelect={3} />
      <SelectModal
        activeModal={controlModal}
        setActiveModal={() => setModalControl(!controlModal)}
      />
      <div className="w-4/4 flex items-center justify-center bg-blue-400 p-2 m-2">
        <div className="w-4/4 flex items-center justify-center bg-blue-400 p-2 rounded-t-lg rounded-b-lg">
          {'Usuário: ' + userSelect?.nome}
        </div>
      </div>

      <form
        onSubmit={handleSubmit((val) => setDataForm(val))}
        className="h-3/4 overflow-y-auto"
      >
        <div className="xl:p-8 md:p-8 2xl:p-8 sm:p-2 ">
          {questions?.map((val, index) => {
            return (
              <div key={val.perguntaId}>
                <div {...register(`respostas.${index}.observacao`)}></div>
                <div {...register(`respostas.${index}.perguntaId`)}></div>

                <>
                  {setValue(`respostas.${index}.perguntaId`, val?.perguntaId)}
                </>

                <CardQuestion
                  {...register(`respostas.${index}.resposta`)}
                  key={val.perguntaId}
                  onErro={
                    dataForm?.respostas ? validateInput(dataForm, index) : false
                  }
                  question={index + 1 + ' - ' + val?.textoPergunta}
                  type={val.tipoResposta}
                  observation={(val) => {
                    setValue(`respostas.${index}.observacao`, val);
                    if (index == 35 || index == 34) {
                      setValue(`respostas.${index}.observacao`, undefined);
                      setValue(`respostas.${index}.resposta`, val);
                    }
                  }}
                  change={(val) => {
                    setValue(`respostas.${index}.resposta`, val);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="h-20 bg-white shadow-2xl fixed bottom-0  w-screen flex flex-row justify-between items-center pl-2 pr-2">
          <div className="w-screen flex flex-row justify-between">
            <Button variant="contained" fullWidth type="submit">
              SALVAR
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
