import axios from 'axios';
import { decodeToken, modeApplication } from '../config/config';
export const apiDeclaracaoSaude = axios.create({
  baseURL: modeApplication(),
  timeout: 20000,
  headers: {
    'Content-type': 'application/json',
  },
});
export const api = axios.create({
  timeout: 3000, // 3 seconds
  headers: {
    'Content-type': 'application/json',
  },
});

apiDeclaracaoSaude.interceptors.request.use(
  async (config) => {
    if (config?.headers?.Authorization) {
      const token = config.headers.Authorization.toString().split(' ')[1];

      if (token) {
        const decodedToken = decodeToken(token);
        const currentTime = Date.now() / 1000;
        // Verificar se o token está expirado
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('authToken');
          // alert('Token expirado, realize o login novamente...');
          // Token expirado, realizar uma ação adequada
          // Por exemplo, redirecionar o usuário para a página de login
          window.location.href = '/';
        }
      }
    } else {
      // Tenta pegar o token do localStorage
      const token = localStorage.getItem('authToken');
      if (token) {
        const decodedToken = decodeToken(JSON.parse(token));
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          config.headers.Authorization = 'Bearer ' + JSON.parse(token); // Atualiza o header Authorization na requisição atual
        } else {
          localStorage.removeItem('authToken');
          // alert('Token expirado, realize o login novamente...');
          window.location.href =
            '/?drc=' +
            localStorage.getItem('declaracaoId') +
            '&operation=' +
            localStorage.getItem('IMG');
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
